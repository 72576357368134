import React from "react";
import { BookOpenIcon } from "@heroicons/react/solid";
import { hobbies } from "../data";

export default function Hobbies() {
    return (
        <section  id="hobbies" className="text-gray-400 bg-gray-900 body-font">
            <div className="container px-5 py-10 mx-auto text-center lg:px-40">
                <div className="flex flex-col w-full mb-20">
                    <BookOpenIcon className="mx-auto inline-block w-10 mb-4" />
                    <h1 className="sm:text-4x1 text-3x1 font-medium title-font mb-4 text-white">
                        Hobbies
                    </h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                    When I'm not working hard on learning new technologies or keeping my clients steady state operations running smoothly, I love to keep myself busy with my hobbies. In recent years I've focused more and more on getting out into nature, but I also love to cook and bake. Reading has been a huge focus in my life for a long time as well, I love the ability to be able to experience an entirely new world through reading.                    </p>
                </div>
                <div className="flex flex-wrap -m-4">
                    {hobbies.map((hobby) =>(
                        <div className="sm:w-1/2 w-100 p-4">
                            <div className="flex relative">
                                <img
                                  alt="gallery"
                                  className="absolute inset-0 w-full h-full object-cover object-center opacity-0"
                                  src={hobby.image}
                                /> 
                                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-100">
                                    <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                                        {hobby.name}
                                    </h2>
                                    <p className="leading-relaxed">{hobby.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}