import React from "react";
import myPicture from './me.jpg';

export default function About() {
    return (
        <section id="about">
            <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                        Hi, I'm Brian!
                        <br className="hidden lg:inline-block" /> I love building resilient systems.
                    </h1>
                    <p className="mb-8 leading-relaxed">
                    I have 7.5 years of experience in the world of technical consulting. I've spent the majority of my time directly supporting clients all the way from low level support up to managing the day-to-day operations in conjunction with managed services supporting large-scale public-sector ERP. I have experience with a variety of software and excel in building out system architecture and troubleshooting.                    </p>
                    <div className="flex justify-center">
                        <a
                            href="#contact"
                            className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
                            Contact Me
                        </a>
                        <a
                            href="#projects"
                            className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
                            Check out my experience
                        </a>
                    </div>
                </div>
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    <img
                        className="object-cover object-center rounded"
                        alt="Hello it me, I'm Brian"
                        src={myPicture}
                    />
                </div>
            </div>
        </section>
    );
}