import { MailOpenIcon, AtSymbolIcon, ChatAltIcon, CodeIcon } from "@heroicons/react/solid";
import React from "react";

export default function Contact() {
    return(
        <section id="contact" className="text-gray-400 bg-gray-900 body-font">
            <div className="container px-5 py-10 mx-auto text-center lg:px-40">
                <div className="flex flex-col w-full mb-20">
                    <MailOpenIcon className="mx-auto inline-block w-10 mb-4" />
                    <h1 className="sm:text-4x1 text-3x1 font-medium title-font mb-4 text-white">
                        Contact Me
                    </h1>
                </div>
                <div class="flex flex-col w-full mb-20 items-center">
                    <div className="sm:w-1/2 w-100 p-4">
                        <div className="flex relative">
                            <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-100">
                                <h1 className="title-font text-lg font-medium text-white mb-3">
                                    You can find me at any of these locations
                                </h1>
                                    <p className="leading-relaxed"> 
                                        <a href="mailto:bnberg@live.com" target="_blank" rel="noopener noreferrer" className="hover:text-white">
                                            <AtSymbolIcon className="mx-auto inline-block w-5 mb-1" /> email me
                                        </a>
                                    </p>
                                    <p className="leading-relaxed">
                                        <a href="https://www.linkedin.com/in/bnberg/" className="hover:text-white">
                                            <ChatAltIcon className="mx-auto inline-block w-5 mb-1" />  linkedin
                                        </a>
                                    </p>
                                    <p className="leading-relaxed">
                                         <a href="https://github.com/Valiken" className="hover:text-white">
                                             <CodeIcon className="mx-auto inline-block w-5 mb-1" /> github
                                        </a>
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}