export const projects = [
    {
        title: "Budget Software Upgrade (2021)",
        subtitle: "Lead Technical Resource",
        description: "Led the technical upgrade effort for the supporting systems used by the clients ERP budgeting software. During this six month project I updated all of the system integrations, updated and migrated the Business Objects universes and updated and tested 150 Business Objects reports. I also worked with the client and our support groups to coordinate migrations, troubleshooting and environment testing and shakedown.",
    },
    {
        title: "ERP Integration Modernization (2019 - 2020)",
        subtitle: "Scrum Master & CI/CD Developer",
        description: "Led a project to modernize existing core ERP infrastructure to add a full REST API. This project introduced a full suite of transaction processing and reference data endpoints. Additionally, I built out a full Jenkins based CI pipeline to automate full end to end unit testing and deployment in our test regions freeing up developers to focus on development.",
    },
    {
        title: "ERP Cloud Implementation & Migration (2017 - 2018)",
        subtitle: "Technical Project Manager & Lead Developer",
        description: "As a deputy technical project manager, I helped to manage the migration from our client's on-premise ERP architecture into CGI's managed services cloud. This project involved converting and testing the entire suite of roughly 200 nightly cycle scripts and a conversion from BASH to PowerShell. We also led the entire performance testing effort to ensure that the new environments would perform at the same level as the existing on-prem infrastructure.",
    }, 
    {
        title: "Warehouse Mobile Application (2016 - 2017)",
        subtitle: "Scrum Master & Developer",
        description: "This was my initial role as a scrum master. Initially I started working on the application as a developer and moved to a scrum master role. We developed a custom mobile application to allow for the client's warehouse staff to efficiently count stock, reconcile and complete receiving orders, transfer stock between warehouses, and allow for issuing returns and picking stock items from the warehouse. This project involved managing time split between two separate development groups to build out both the front-end hybrid mobile app built with JQuery Mobile & PhoneGap as well as a backend SOAP web service. I also assisted the clients in deciding on ruggedized devices and led the testing effort during the development phase of the project.",
    },
    {
        title: "Core Financial ERP Upgrade (2015 - 2016)",
        subtitle: "Integration Developer & Configuration Management",
        description: "Expanded my role as a Configuration Management specialist, I installed clustered and configured WebSphere servers, setup and maintained business objects and learned the ins and out of SQL and performance tuning for large scale java-based systems. In addition to this, I also maintained and supported updates to the existing nightly cycle scripts and processes. I was also exposed to a larger amount of client interaction in this role where I worked directly with the client's IT staff to facilitate server setup and configuration and performed knowledge transfer to allow for a clean handoff post go-live.",
    }, 
    {
        title: "Budgeting Software Implementation (2014 - 2015)",
        subtitle: "Systems & Configuration Management",
        description: "Supported the upgrade from a legacy budgeting system to CGI Performance Budgeting. In this role I setup, installed and maintained the application servers, third party software and application installation in both production and non-production environments. This was my first real work experience as an IT consultant, and I learned a ton. The majority of my project time was spent setting up and maintaining the production environments, as well as, migrating and deploying application packages and coordinating with the client for setting up the nightly cycle and back up processes to ensure our clients were supported in their day-to-day activities.",
    },    
];

export const personalProjects = [];

export const testimonials = [
    {
        quote: "Brian is a pleasure to work with. He is always more than willing to help is colleagues regardless of the circumstances. I have reported to him directly in the past and have found his leadership skills to be top notch. He is very talented at getting results from his team as well as motivating them. His direct philosophy is to make things correctly and to strive to make them better. He focuses on ingraining this philosophy in his team and I believe it often results in a better product feature or a more efficient development process. Whether Brian is in a leadership role or on the daily grind with his team, he always performs beyond what is expected of him and I do think that makes him an essential member of any team.",
        image: "",
        name: "Brian Sandoval",
        company: "CGI",
    },
    {
        quote: "Brian is a thoughtful I.T. leader who is able to lead complex projects in chaotic environments. At CGI he is one of the go to Technical Leads and has a rare talent of communicating highly technical specifications to your everyday user. Brian builds things right, is a great project manager, and a true team player. Any company would be blessed to have him he brings deep value to the industry.",
        image: "",
        name: "Rishi Basu",
        company: "CGI",
    },
];

export const skills = [
        "Certified SAFe Agilist",
        "Certified SAFe Scrum Master",
        "PowerShell Scripting",
        "BASH & KornShell Scripting",
        "Python",
        "Oracle SQL",
        "Red Hat Enterprise Linux",
        "Windows Server",
        "WebSphere Administration",
        "Jenkins",
        "Client support",
        "Problem Solving & Troubleshooting",
        "Microsoft Office Suite",
        "Technical Project Management",
];

export const hobbies = [
    {
        name: "Photography",
        description: "I love taking pictures while I'm out and about! This has been an on and off hobby of mine since I took a photography class all the way back in middle school. I focus mostly on nature, landscapes & animal photography",
        image: "./public/images/pagoda.jpg",
    },
    {
        name: "Hiking",
        description: "I've been an avid hiker for the last 6 years. Generally, when I'm not busy and it’s not too hot outside I try to hit the trails near Pasadena, a personal favorite of mine is hiking Baden Powell out in the San Gabriel mountains. When I go on vacation or visit my friends up in Seattle finding a new trail is always on my radar.",
        image: "/public/images/badenpowell.jpg",
    },
    {
        name: "Baking",
        description: "Over the pandemic I've gotten very into baking bread. However, before the pandemic I loved making pie and other assorted deserts. I try to reserve a couple of weekends a month to either bake a couple of loaves of bread or make a pie for my family and friends.",
        image: "/public/images/gallette.jpg",
    },
    {
        name: "Reading",
        description: "I've been an avid reader since I first read the Dragon Riders of Pern back in the 7th grade. That series of novels got me hooked on Sci-Fi and fantasy and I haven't looked back. In recent years I've read a lot of Brandon Sanderson and works like his. One of my favorite works of Sci-Fi would have to be Dune due to the general tone and feel of the first novel. I also have an interest in Detective novels, such as Michael Connelly. When I'm not reading fiction, I read a smattering of books that generally focus on anthropology and the outdoors, shout out to Running with Sherman.",
        image: "./",
    },
];